// Skills.tsx

import { FC, useState } from "react";
import "./styles/skills.css";
import CONSTANTS from "../../constants/constants";
import { SkillsData } from "../../models/portfolio-data";

export const Skills: FC = () => {
  const [skillsData] = useState<SkillsData>(CONSTANTS.SKILLS_DATA);

  return (
    <div className="skills_container" id="skills">
      <h2 className="section_heading">Technologies</h2>
      <div className="skills_grid">
        <div className="skills_card">
          <h3>Languages</h3>
          <ul className="skills_list_item_ul">
            {skillsData.PROGRAMMING_LANGUAGES.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills_card">
          <h3>Frontend</h3>
          <ul className="skills_list_item_ul">
            {skillsData.FRONTEND.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills_card">
          <h3>Backend</h3>
          <ul className="skills_list_item_ul">
            {skillsData.BACKEND.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills_card">
          <h3>Database</h3>
          <ul className="skills_list_item_ul">
            {skillsData.DATABASE.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills_card">
          <h3>Devops</h3>
          <ul className="skills_list_item_ul">
            {skillsData.DEVOPS.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills_card">
          <h3>Tools</h3>
          <ul className="skills_list_item_ul">
            {skillsData.TOOLS.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
