import { FC } from "react";
import "./styles/about.css";
import CONSTANTS from "../../constants/constants";
import aboutImage from "../../assets/images/about-image.png";
import { PiHandWaving } from "react-icons/pi";
import { ImageCard } from "./ImageCard";
import { Contact } from "../Contact/Contact";

export const About: FC = () => {
  return (
    <div className="about_container" id="about">
      <div className="about_content">
        <div className="about_text_container">
          <h1 className="about_title">
            Hi, I&apos;m{" "}
            <span className="about_name">
              {CONSTANTS.ABOUT_DATA.DISPLAY_NAME}
            </span>
            <span className="wave_emoji_container">
              <PiHandWaving className="wave_emoji" />
            </span>
          </h1>
          <p className="about_text">{CONSTANTS.ABOUT_DATA.INTRO}</p>
          <Contact />
        </div>
        <div className="about_image">
          <ImageCard image={aboutImage} />
        </div>
      </div>
    </div>
  );
};
