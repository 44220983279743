// Experience.tsx
import { FC } from "react";
import "./styles/experience.css";
import CONSTANTS from "../../constants/constants";
import { ExperienceData } from "../../models/portfolio-data";
import { CompanyCard } from "./CompanyCard";

export const Experience: FC = () => {
  const experienceData: ExperienceData = CONSTANTS.EXPERIENCE_DATA;

  return (
    <div className="experience_container" id="experience">
      <h2 className="section_heading">Experience</h2>
      <div className="company_card_containers">
        {experienceData.EXPERIENCES.map((company, index) => (
          <CompanyCard
            key={company.COMPANY}
            index={index + 1}
            companyData={company}
          />
        ))}
      </div>
    </div>
  );
};
