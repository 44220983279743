import { FC, useState } from "react";
import "./styles/toggle-switch.css";
import CONSTANTS from "../../constants/constants";
import { useTheme } from "../../contexts/ThemeContext";

export const ToggleSwitch: FC = () => {
  const { theme, toggleTheme } = useTheme();
  const [isDarkMode, setDarkMode] = useState<boolean>(
    theme === CONSTANTS.APP_THEMES.DARK
  );

  const toggleSwitchHandler = () => {
    setDarkMode(!isDarkMode);
    toggleTheme?.();
  };

  return (
    <div
      className={`darkmode-toggle-wrapper ${
        isDarkMode ? "darkmode-switch" : ""
      }`}
    >
      <input
        type="checkbox"
        id="darkmode-toggle"
        aria-label="dark mode toggle"
        className="darkmode-toggle-checkbox"
        checked={isDarkMode}
        onChange={toggleSwitchHandler}
      />
      <div className="darkmode-toggle-circle"></div>
      <span className="darkmode-toggle-emoji">🌚</span>
      <span className="darkmode-toggle-emoji">🌞</span>
    </div>
  );
};
