import { FC, useState } from "react";
import "./styles/education.css";
import CONSTANTS from "../../constants/constants";
import { EducationData } from "../../models/portfolio-data";

export const Education: FC = () => {
  const [education] = useState<EducationData>(CONSTANTS.EDUCATION_DATA);

  return (
    <div className="education_container" id="education">
      <h2 className="section_heading">Education</h2>
      <div className="education_content">
        <div className="education_item">
          <p className="education_university_name">
            {education.EDUCATION[0].UNIVERSITY}
          </p>
          <p className="education_degree">
            {education.EDUCATION[0].DEGREE} in {education.EDUCATION[0].MAJOR}
          </p>
          <p className="education_graduation_date">
            {education.EDUCATION[0].GRADUATION_DATE}
          </p>
          <p className="education_cgpa">{education.EDUCATION[0].MARKS}</p>
        </div>
      </div>
    </div>
  );
};
