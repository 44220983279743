import { FC, useEffect, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import "./styles/portfolio-page.css";
import CONSTANTS from "../constants/constants";

import { Navbar } from "../components/Navbar/Navbar";
import { About } from "../components/About/About";
import { Experience } from "../components/Experience/Experience";
import { Skills } from "../components/Skills/Skills";
// import { Projects } from "../components/Projects/Projects";
import { Education } from "../components/Education/Education";

export const PortfolioPage: FC = () => {
  const { theme } = useTheme();
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    if (theme === CONSTANTS.APP_THEMES.DARK) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, [theme]);

  return (
    <div
      className={`portfolio-page_container ${
        darkMode ? "darkmode-portfolio" : ""
      }`}
    >
      <Navbar />
      <About />
      <Experience />
      <Skills />
      {/* <Projects /> */}
      <Education />
    </div>
  );
};
