import { FC, createContext, useContext, useState, ReactNode } from "react";

// Import CONSTANTS
import CONSTANTS from "../constants/constants";

// Define interface for ThemeProvider props
interface ThemeProviderProps {
  children: ReactNode;
}

// Define interface for theme context value
interface ThemeContextValue {
  theme: string;
  toggleTheme?: () => void;
}

// Create context with initial value
const ThemeContext = createContext<ThemeContextValue>({
  theme: CONSTANTS.APP_THEMES.LIGHT,
  toggleTheme: undefined
});

// ThemeProvider component
export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>(CONSTANTS.APP_THEMES.LIGHT);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === CONSTANTS.APP_THEMES.LIGHT
        ? CONSTANTS.APP_THEMES.DARK
        : CONSTANTS.APP_THEMES.LIGHT
    );
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);
