import { FC } from "react";
import { ThemeProvider } from "./contexts/ThemeContext";
import { PortfolioPage } from "./pages/PortfolioPage";

export const App: FC = () => {
  return (
    <ThemeProvider>
      <PortfolioPage />
    </ThemeProvider>
  );
};

export default App;
