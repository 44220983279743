import { FC } from "react";
import "./styles/image-card.css";

export interface ImageCardProps {
  image: string;
}

export const ImageCard: FC<ImageCardProps> = ({ image }) => {
  return (
    <div className="image_card-container">
      <img src={image} className="image_card" alt="About Image" />
    </div>
  );
};
