// CompanyCard.tsx
import { FC } from "react";
import "./styles/company-card.css";
import { CompanyData } from "../../models/portfolio-data";

interface Props {
  index: number;
  companyData: CompanyData;
}

export const CompanyCard: FC<Props> = ({ index, companyData }) => {
  return (
    <div className="company_card">
      <p className="company_card_index">{index}</p>
      <div className="company_card_header">
        <span className="card_header_left">
          <p className="card_header_left_name">{companyData.COMPANY}</p>
          <p className="card_header_left_position">{companyData.POSITION}</p>
        </span>
        <span className="card_header_right">
          <p>
            {companyData.START_DATE} - {companyData.END_DATE}
          </p>
          <p>{companyData.LOCATION}</p>
        </span>
      </div>
      <div className="card_content">
        <p>{companyData.DESCRIPTION}</p>
      </div>
    </div>
  );
};
