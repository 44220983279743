import { FC } from "react";
import "./styles/navbar.css";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";

export const Navbar: FC = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="navbar_container" id="navbar">
      <a href="/" className="navbar_logo">
        <span className="navbar_logo-arrow">&lt;</span>
        <span className="navbar_logo-name">Ayush Aryan</span>
        <span className="navbar_logo-arrow">/&gt;</span>
      </a>
      <div className="navbar_menu">
        <div className="navbar_menu_items">
          <a href="#experience" onClick={() => scrollToSection("experience")}>
            Experience
          </a>
          <a href="#skills" onClick={() => scrollToSection("skills")}>
            Skills
          </a>
          {/* <a href="#projects" onClick={() => scrollToSection("projects")}>
            Projects
          </a> */}
          <a href="#education" onClick={() => scrollToSection("education")}>
            Education
          </a>
          {/* <a href="#contact" onClick={() => scrollToSection("contact")}>
            Contact
          </a> */}
        </div>
        <div className="navbar_menu_toggle">
          <ToggleSwitch />
        </div>
      </div>
    </div>
  );
};
