import { FC, useState } from "react";
import "./styles/contact.css";
import CONSTANTS from "../../constants/constants";
import { MdEmail } from "react-icons/md";
import { FiGithub, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";
// import { FaLinkedin, FaStackOverflow, FaQuora, FaCoffee } from "react-icons/fa";
import { FaLinkedin, FaQuora } from "react-icons/fa";
// import { BiSolidConversation } from "react-icons/bi";
import { ContactData } from "../../models/portfolio-data";

export const Contact: FC = () => {
  const [contact] = useState<ContactData>(CONSTANTS.CONTACT_DATA);

  return (
    <div className="contact_container" id="contact">
      <div className="contact_content">
        <p className="contact_email">
          <MdEmail /> {contact.EMAIL}
        </p>{" "}
        <div className="contact_links">
          <a href={contact.LINKEDIN} target="_blank" rel="noreferrer">
            <FaLinkedin />
          </a>
          <a href={contact.TWITTER} target="_blank" rel="noreferrer">
            <FiTwitter />
          </a>
          <a href={contact.INSTAGRAM} target="_blank" rel="noreferrer">
            <FiInstagram />
          </a>
          <a href={contact.GITHUB} target="_blank" rel="noreferrer">
            <FiGithub />
          </a>
          {/* <a href={contact.STACKOVERFLOW} target="_blank" rel="noreferrer">
            <FaStackOverflow />
          </a> */}
          <a href={contact.QUORA} target="_blank" rel="noreferrer">
            <FaQuora />
          </a>
          {/* <a href={contact.TOPMATE} target="_blank" rel="noreferrer">
            <BiSolidConversation />
          </a> */}
          {/* <a href={contact.BUYMEACOFFEE} target="_blank" rel="noreferrer">
            <FaCoffee />
          </a> */}
          <a href={contact.YOUTUBE} target="_blank" rel="noreferrer">
            <FiYoutube />
          </a>
        </div>
      </div>
    </div>
  );
};
